<template>
    <el-dialog v-model="isShowCashierList" width="800px" :draggable="true" :destroy-on-close="true">
        <template #header>
            商户名称：{{ merchantInfo.merchant_name }}
        </template>
        <div class="table-head">
            <span class="table-title">收银员列表</span>
            <span class="table-btn">
                <el-button @click="addCashier" type="primary">
                    <el-icon style="color:#fff">
                        <Plus />
                    </el-icon>
                    <span style="margin-left:15px">添加收银员</span>
                </el-button>
            </span>
        </div>
        <c-table ref="cashierTableRef" :tableDataUrl="cashierListTableDataUrl" :isLoadData="false">
            <el-table-column type="index" width="55" label="序号" :align="'center'" />
            <el-table-column prop="name" label="收银员姓名" />
            <el-table-column prop="phone" label="收银员电话" />
            <el-table-column label="创建时间">
                <template #default="scope">
                    {{ timeFormat(scope.row.created_at) }}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-link type="primary" @click="editCashier(scope.row)">编辑</el-link>
                    <el-popconfirm title="确认删除吗？" @confirm="deleteCashier(scope.row)">
                        <template #reference>
                            <el-link style="margin-left:15px" type="primary">删除
                            </el-link>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </c-table>
    </el-dialog>
    <el-dialog v-model="isShowCashierAdd" width="500px" :draggable="true" :destroy-on-close="true" @close="close">
        <template #header>
            添加收银员
        </template>
        <el-form ref="cashierFormRef" :model="cashierForm" label-width="120px" :rules="rules">
            <el-row>
                <el-col :span="20">
                    <el-form-item label="姓名：" prop="name">
                        <el-input v-model="cashierForm.name" :value="cashierForm.name" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="电话：" prop="phone">
                        <el-input v-model="cashierForm.phone" :value="cashierForm.phone" maxlength="11" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="登录密码：" prop="password">
                        <el-input v-model="cashierForm.password" :value="cashierForm.password" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="绑定门店：" prop="store_ids">
                        <el-select v-model="cashierForm.store_ids" multiple style="width: 240px">
                            <el-option v-for="store in stores" :key="store.id" :label="store.name" :value="store.id" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" v-if="isEdit" @click="editSubmit">确定</el-button>
            <el-button type="primary" v-else @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, defineExpose, getCurrentInstance } from 'vue'
import cTable from '@/components/CustomTable'
import { Plus } from '@element-plus/icons-vue'
import { nextTick } from 'process';
import { phone } from '@/func/validatorFunc'
import { ElMessage } from 'element-plus'
import { timeFormat } from '@/func/time'

const { proxy } = getCurrentInstance() // 当前实例

const cashierTableRef = ref(null)
const cashierFormRef = ref(null)

const isShowCashierList = ref(false)
const isShowCashierAdd = ref(false)
const isEdit = ref(false)
const cashierListTableDataUrl = proxy.$api.merchant.cashier.list
const merchantInfo = ref(null)
const stores = ref([])
const cashierForm = ref({
    merchant_id: '',
    name: '',
    password: '',
    phone: '',
    store_ids: []
})
const rules = ref({
    name: [
        { required: true, message: '此项必填' }
    ],
    password: [
        { required: true, message: '此项必填' }
    ],
    phone: [
        { required: true, message: '此项必填' },
        { validator: phone, trigger: 'change' }
    ],
    store_ids: [
        { required: true, message: '此项必填' }
    ],
})

const addCashier = () => {
    isShowCashierAdd.value = true
    cashierForm.value.merchant_id = merchantInfo.value.id
    proxy.$api.merchant.store.list({ merchant_id: merchantInfo.value.id }).then((r) => {
        stores.value = r.data
    })
}
const cancel = () => {
    isShowCashierAdd.value = false
    cashierForm.value = {
        name: '',
        password: '',
        phone: '',
        store_ids: []
    }
}

const submit = () => {
    proxy.$refs.cashierFormRef.validate(validate => {
        if (validate) {
            proxy.$api.merchant.cashier.add(cashierForm.value).then(r => {
                if (r.status == 200) {
                    nextTick(() => {
                        cashierTableRef.value.refresh()
                        isShowCashierAdd.value = false
                        cashierForm.value = {
                            name: '',
                            password: '',
                            phone: '',
                            store_ids: []
                        }
                    })
                } else {
                    ElMessage({
                        message: r.error.message, type: 'error'
                    })
                }
            })
        }
    })
}

const editCashier = (item) => {
    isShowCashierAdd.value = true
    isEdit.value = true
    proxy.$api.merchant.store.list({ merchant_id: merchantInfo.value.merchant_id }).then((r) => {
        stores.value = r.data
    })

    cashierForm.value.id = item.id
    cashierForm.value.merchant_id = item.merchant_id
    cashierForm.value.name = item.name
    cashierForm.value.password = '******'
    cashierForm.value.phone = item.phone
    cashierForm.value.store_ids = []

    item.stores.forEach((element) => {
        cashierForm.value.store_ids.push(element.id)
    })
}

const editSubmit = () => {
    proxy.$refs.cashierFormRef.validate(validate => {
        if (validate) {
            proxy.$api.merchant.cashier.edit(cashierForm.value.id, cashierForm.value).then(r => {
                if (r.status == 200) {
                    nextTick(() => {
                        cashierTableRef.value.refresh()
                        isShowCashierAdd.value = false
                        cashierForm.value = {
                            name: '',
                            password: '',
                            phone: '',
                            store_ids: []
                        }
                        isEdit.value = false
                    })
                } else {
                    ElMessage({
                        message: r.error.message, type: 'error'
                    })
                }
            })
        }
    })
}

const deleteCashier = (item) => {
    proxy.$api.merchant.cashier.delete(item.id).then(r => {
        if (r.status == 200) {
            nextTick(() => {
                cashierTableRef.value.refresh()
            })
        } else {
            ElMessage({
                message: r.error.message, type: 'error'
            })
        }
    })
}

const close = () => {
    cashierForm.value = {
        name: '',
        password: '',
        phone: '',
        store_ids: []
    }
}

const open = (item) => {
    merchantInfo.value = item
    isShowCashierList.value = true

    nextTick(() => {
        cashierTableRef.value.setCustomLoad(isShowCashierList.value)
        cashierTableRef.value.setParams({ merchant_id: item.id })
    })
}

defineExpose({ open })
</script>